import c0 from './annotations_controller';
import c1 from './autocomplete_controller';
import c2 from './clipboard_controller';
import c3 from './dropzone_controller';
import c4 from './history_controller';
import c5 from './inplace_input_controller';
import c6 from './login_form_controller';
import c7 from './menu_controller';
import c8 from './panel_controller';
import c9 from './pass_reveal_controller';
import c10 from './remote_controller';
import c11 from './remover_controller';
import c12 from './request_controller';
import c13 from './scroll_indicator_controller';
import c14 from './scrollto_controller';
import c15 from './styler_controller';
import c16 from './theme_controller';
import c17 from './timetoken_controller';
import c18 from './turbo_form_controller';
import c19 from './turbo_refresh_controller';
import c20 from './turbo_reload_controller';
import c21 from './video_controller';
export const definitions = [
	{identifier: 'annotations', controllerConstructor: c0},
	{identifier: 'autocomplete', controllerConstructor: c1},
	{identifier: 'clipboard', controllerConstructor: c2},
	{identifier: 'dropzone', controllerConstructor: c3},
	{identifier: 'history', controllerConstructor: c4},
	{identifier: 'inplace-input', controllerConstructor: c5},
	{identifier: 'login-form', controllerConstructor: c6},
	{identifier: 'menu', controllerConstructor: c7},
	{identifier: 'panel', controllerConstructor: c8},
	{identifier: 'pass-reveal', controllerConstructor: c9},
	{identifier: 'remote', controllerConstructor: c10},
	{identifier: 'remover', controllerConstructor: c11},
	{identifier: 'request', controllerConstructor: c12},
	{identifier: 'scroll-indicator', controllerConstructor: c13},
	{identifier: 'scrollto', controllerConstructor: c14},
	{identifier: 'styler', controllerConstructor: c15},
	{identifier: 'theme', controllerConstructor: c16},
	{identifier: 'timetoken', controllerConstructor: c17},
	{identifier: 'turbo-form', controllerConstructor: c18},
	{identifier: 'turbo-refresh', controllerConstructor: c19},
	{identifier: 'turbo-reload', controllerConstructor: c20},
	{identifier: 'video', controllerConstructor: c21},
];
